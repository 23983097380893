export const roles = {
  ADMIN: {
    value: 'ADMIN',
    label: 'Admin',
    labelJa: '管理者',
  },
  SALES: {
    value: 'SALES',
    label: 'Sales Planning',
    labelJa: '販売推進',
  },
  PROMOTION: {
    value: 'PROMOTION',
    label: 'Sales Promotion',
    labelJa: '販売促進',
  },
  DATA_INSIGHT: {
    value: 'DI',
    label: 'Data Insight',
    labelJa: 'データインサイト',
  },
  OPS: {
    value: 'OPS',
    label: 'Operator',
    labelJa: '運用',
  },
  CS: {
    value: 'CS',
    label: 'Customer Support',
    labelJa: 'CS',
  },
  PO: {
    value: 'PO',
    label: 'Property Owner',
    labelJa: '物件オーナー',
  },
  HC: {
    value: 'HC',
    label: 'Housekeeping Company',
    labelJa: '清掃会社',
  },
  HKP: {
    value: 'HKP',
    label: 'Housekeeper',
    labelJa: '清掃担当者',
  },
};
