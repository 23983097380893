<template>
  <div>
    <a-select
      v-model:value="lang"
      :filter-option="filterOption"
      :size="size"
      show-search
      @change="handleChange"
    >
      <a-select-option v-for="(label, key) in languages" :key="label" :value="key">
        {{ $t(label) }}
      </a-select-option>
    </a-select>
  </div>
</template>

<i18n>
{
  "en": {
    "ENGLISH": "English",
    "JAPANESE": "Japanese",
  },
  "ja": {
    "ENGLISH": "英語",
    "JAPANESE": "日本語",
  }
}
</i18n>

<script>
import languages from './languages';

export default {
  name: 'InputLanguage',
  props: {
    valueModel: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:valueModel'],
  data() {
    return {
      languages,
      lang: '',
    };
  },
  watch: {
    valueModel: {
      immediate: true,
      handler(nv) {
        this.lang = nv;
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.lang);
      this.$emit('update:valueModel', this.lang);
    },
  },
};
</script>

<style scoped></style>
