<template>
  <div>
    <a-select
      v-model:value="role"
      :filter-option="filterOption"
      :size="size"
      style="width: 100%"
      show-search
      @change="handleChange"
    >
      <a-select-option v-for="r in filteredRoles" :key="r.label" :value="r.value">
        {{ r[labelKey] }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { roles } from './roles';

export default {
  name: 'InputRole',
  props: {
    valueModel: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:valueModel'],
  data() {
    return {
      roles: Object.values(roles),
      role: '',
    };
  },
  computed: {
    filteredRoles() {
      const userRoles = this.$store.getters.roles;
      if (userRoles.some((r) => ['HC', 'HKP'].includes(r))) {
        const keys = Object.keys(roles).filter((k) => k === 'HKP');
        return keys.map((k) => roles[k]);
      }
      if (userRoles.some((r) => ['PO'].includes(r))) {
        const keys = Object.keys(roles).filter((k) => ['HKP', 'HC', 'PO'].includes(k));
        return keys.map((k) => roles[k]);
      }
      return roles;
    },
    labelKey() {
      const keys = {
        en: 'label',
        ja: 'labelJa',
      };
      return this.$i18n.locale in keys ? keys[this.$i18n.locale] : keys.en;
    },
  },
  watch: {
    valueModel: {
      immediate: true,
      handler(nv) {
        this.role = nv;
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.role);
      this.$emit('update:valueModel', this.role);
    },
  },
};
</script>

<style scoped></style>
