<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add New User') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mini">
        <UserForm
          ref="userForm"
          :is-submitting="isSubmitting"
          :partner-list="partnerList"
          show-roles
          @submit="handleSubmit"
        />
        <a-alert v-if="user" type="success">
          <template #message>
            <span v-html="$t('user-added', { username: getUserFullname(user) })" />
            <router-link to="/users">
              {{ $t('View users page') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en":{
"Add New User":"Add New User",
"View users page": "View users page",
"user-added": "User <strong>{username}</strong> added,"
},
"ja":{
"Add New User":"新規ユーザーの追加",
"View users page": "ユーザーページ",
"user-added": "ユーザ<strong>{username}</strong>が追加されました,"
}
}
</i18n>

<script>
import UserForm from '@/views/user/components/UserForm';
import { getFullname } from '@/utils/users';

export default {
  name: 'AddUser',
  components: { UserForm },
  data() {
    return {
      user: undefined,
      isSubmitting: false,
      error: '',
      partnerList: [],
    };
  },
  created() {
    const disallowed = ['HC', 'PO'];
    const { roles } = this.$store.getters;
    const isDisallowed = roles.some((r) => disallowed.includes(r));
    if (!isDisallowed) {
      this.getPartnerList();
    }
  },
  methods: {
    getPartnerList() {
      this.$store.dispatch('partners/list', { infinite: true }).then((res) => {
        this.partnerList = res.list;
      });
    },
    handleSubmit(data) {
      this.isSubmitting = true;
      this.user = undefined;
      this.error = '';
      // eslint-disable-next-line no-unused-vars
      const { ...payload } = data;
      this.$store
        .dispatch('user/add', payload)
        .then(() => {
          this.isSubmitting = false;
          this.user = data;
          this.$refs.userForm.resetFields();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            this.error = err.response.data.error.message;
          }
          this.isSubmitting = false;
        });
    },
    getUserFullname(record) {
      return getFullname({
        fname: record.firstName,
        lname: record.lastName,
      });
    },
  },
};
</script>
