<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Users') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div v-permission="['add-user']" class="column is-narrow">
            <router-link class="ant-btn ant-btn-lg ant-btn-primary" to="/users/add">
              {{ $t('Add New User') }}
            </router-link>
          </div>
        </div>
        <a-table
          row-key="uid"
          :columns="columns"
          :data-source="users"
          :loading="isFetching"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #name="{ record }">
            {{ getUserFullname(record) }}
          </template>
          <template #types="{ record }">
            {{ $t($filters.roles(record)) }}
          </template>
          <template #status="{ record }">
            <a-switch
              v-if="record.uid !== userUid"
              v-model:checked="record.isActive"
              @change="(status) => { updateUserStatus(status, record); }"
            />
            <a-switch v-else :checked="true" disabled />
          </template>
          <template #action="{ record }">
            <div class="is-flex is-justify-content-flex-end">
              <a
                v-if="!record.hasPassword"
                style="cursor: pointer"
                class="m-r-4x"
                @click="resendEmail(record)"
              >
                {{ $t('resend email') }}
              </a>
              <EditFilled
                v-permission="['modify-user']"
                class="has-text-success-dark mt-1"
                @click="handleEditUser(record)"
              />
              <Delete
                action-handler="user/delete"
                :payload-value="{ uid: record.uid }"
                :hide-handler="record.uid === userUid"
                :handler-v-permission="['modify-user']"
                :title="$t('title')"
                :description="$t('desc', { email: record.email })"
                :success-title="$t('deleted-title')"
                :success-description="$t('deleted-desc', { email: record.email })"
                @close="fetchData"
              />
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Users": "Users",
    "Add New User": "Add New User",
    "User": "User",
    "Email": "Email",
    "Type": "Type",
    "Status": "Status",
    "Email sent": "Email sent",
    "Sending email": "Sending email",
    "resend email": "resend email",
    "title": "Delete User?",
    "desc": "Are you sure you want to delete <strong>{email}</strong> user?",
    "deleted-title": "User is deleted",
    "deleted-desc": "User <strong>{email}</strong> has been removed."
  },
  "ja": {
    "Users": "ユーザー",
    "Add New User": "ユーザーを追加",
    "User": "ユーザー",
    "Email": "メールアドレス",
    "Type": "タイプ",
    "Status": "ステータス",
    "Email sent": "メールを送信しました",
    "Sending email": "メールの送信",
    "resend email": "メールを再送",
    "title": "ユーザーを削除?",
    "desc": "ユーザー <strong>{email}</strong> を本当に削除しますか？",
    "deleted-title": "ユーザーは削除",
    "deleted-desc": "ユーザー <strong>{email}</strong> は削除"
  }
}
</i18n>

<script>
import { EditFilled } from '@ant-design/icons-vue';
import pagination from '@/components/mixins/pagination';
import checkPermission from '@/utils/permission';
import { getFullname } from '@/utils/users';
import SearchForm from './components/SearchForm';
import Delete from '@/components/PopupDelete';

export default {
  name: 'Users',
  components: {
    EditFilled,
    SearchForm,
    Delete,
  },
  mixins: [pagination],
  data() {
    return {
      userUid: this.$store.getters.uid,
      users: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('User'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Email'),
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: this.$t('Type'),
          key: 'types',
          slots: { customRender: 'types' },
        },
        {
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        },
        {
          title: ' ',
          key: 'action',
          width: '200px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    canEditUser() {
      return checkPermission(['modify-user']);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store
        .dispatch('user/users', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;

          this.users = list.map((u, index) => {
            u._id = index + 1;
            return u;
          });

          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updateUserStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store.dispatch('user/update', { uid: record.uid, isActive: status }).finally(() => {
        this.fetchData();
      });
    },
    handleEditUser(record) {
      this.$router.push({ name: 'edit-user', params: { uid: record.uid } });
    },
    resendEmail(record) {
      this.$message.loading('Sending email', 2.5).then(() => {
        this.$store.dispatch('user/resendEmail', { uid: record.uid }).then(() => {
          this.$message.success('Email sent', 2.5);
        });
      });
    },
    getUserFullname(record) {
      return getFullname({
        fname: record.firstName,
        lname: record.lastName,
      });
    },
  },
};
</script>
