<template>
  <a-form ref="userForm" :model="userForm" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="is-block is-flex-tablet" :class="isReserveField">
      <a-form-item class="column is-6" :label="$t('First Name')" name="firstName">
        <a-input v-model:value="userForm.firstName" :size="formSize" type="text" />
      </a-form-item>
      <a-form-item class="column is-6" :label="$t('Last Name')" name="lastName">
        <a-input v-model:value="userForm.lastName" :size="formSize" type="text" />
      </a-form-item>
    </div>

    <a-form-item class="column is-12" :label="$t('Email')" name="email">
      <a-input
        v-model:value.trim="userForm.email"
        :size="formSize"
        autocomplete="new-email"
        type="email"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Default Language')" name="lang">
      <InputLanguage v-model:value-model="userForm.lang" :size="formSize" style="width: 100%" />
    </a-form-item>
    <a-form-item
      v-if="showRoles"
      class="column is-12"
      :label="$t('Type')"
      name="role"
    >
      <InputRole v-model:value-model="userForm.role" :size="formSize" style="width: 100%"
                 @change="handleRoleChange"
      />
    </a-form-item>
    <a-form-item
      v-if="['PO', 'HC', 'HKP', 'CS'].includes(userForm.role) && isInternalUser"
      class="column is-12"
      :label="$t('Partner')"
      name="partnerId"
    >
      <a-select
        v-model:value="userForm.partnerId"
        :size="size"
        mode="multiple"
        :filter-option="filterOption"
      >
        <a-select-option
          v-for="partner in filteredPartner"
          :key="partner.id"
          :name="partner.name"
          :value="partner.id"
        >
          {{ partner.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-if="['HC'].includes(userForm.role) && isInternalUser"
      class="column is-12"
      :label="$t('Properties')"
      name="propertyId"
    >
      <InputProperty v-model:value="userForm.propertyId" :partner-id="singlePartner" mode="multiple" />
    </a-form-item>
    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="formSize"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"First Name": "First Name",
"Last Name": "Last Name",
"Email": "Email",
"Default Language": "Default Language",
"Type": "Type",
"Partner": "Partner",
"Save": "Save",
"first-name-required": "First Name is required.",
"last-name-required": "Last Name is required.",
"email-invalid": "Invalid email format.",
"email-required": "Email is required.",
"partner-required": "Partner is required."
},
"ja": {
"First Name": "名",
"Last Name": "姓",
"Email": "メールアドレス",
"Default Language": "デフォルト言語",
"Type": "タイプ",
"Partner": "取引先",
"Save": "保存",
"first-name-required": "名は必須です",
"last-name-required": "姓は必須です",
"email-invalid": "無効なメールアドレス形式",
"email-required": "メールアドレスは必須です",
"partner-required": "パートナーが必要です"
}
}
</i18n>

<script>
import InputLanguage from '@/components/InputLanguage';
import InputRole from './InputRole';
import { reserveFormat } from '@/utils/util';
import InputProperty from '@/components/InputProperty';

export default {
  name: 'UserForm',
  components: {
    InputProperty, InputLanguage, InputRole,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    user: {
      type: Object,
      default() {
        return null;
      },
    },
    partnerList: {
      type: Array,
      default: () => [],
    },
    showRoles: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['submit'],
  data() {
    return {
      userForm: {
        firstName: '',
        lastName: '',
        email: '',
        lang: 'en',
        role: '',
        partnerId: null,
        propertyId: null,
      },
      userRules: {
        firstName: [
          {
            type: 'string',
            required: true,
            message: this.$t('first-name-required'),
            trigger: ['change', 'blur'],
          },
        ],
        lastName: [
          {
            type: 'string',
            required: true,
            message: this.$t('last-name-required'),
            trigger: ['change', 'blur'],
          },
        ],
        email: [
          {
            type: 'email',
            message: this.$t('email-invalid'),
            trigger: ['change', 'blur', 'input'],
          },
          {
            required: true,
            message: this.$t('email-required'),
            trigger: ['change', 'blur'],
          },
        ],
        partnerId: [
          {
            required: true,
            type: 'array',
            message: this.$t('partner-required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      formSize: 'large',
    };
  },
  computed: {
    isInternalUser() {
      const disallowed = ['HC', 'PO'];
      const { roles } = this.$store.getters;
      return !roles.some((r) => disallowed.includes(r));
    },
    rules() {
      return this.userRules;
    },
    filteredPartner() {
      const { role } = this.userForm;
      if (role === 'PO') {
        return this.partnerList.filter((p) => p.relationship === 'PO');
      }

      if (role === 'CS') {
        return this.partnerList.filter((p) => p.relationship === 'CSC');
      }

      if (role === 'HC' || role === 'HKP') {
        return this.partnerList.filter((p) => p.relationship === 'Vendor');
      }

      return [];
    },
    singlePartner() {
      return this.userForm.partnerId ? this.userForm.partnerId[0] : undefined;
    },
    isReserveField() {
      return reserveFormat() ? 'reserve-fields' : '';
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(nv) {
        this.userForm.firstName = nv ? nv.firstName : '';
        this.userForm.lastName = nv ? nv.lastName : '';
        this.userForm.email = nv ? nv.email : '';
        this.userForm.lang = nv ? nv.lang : '';
        this.userForm.role = nv ? nv.role : '';
        this.userForm.partnerId = nv && nv.partner ? nv.partner.map((p) => p.id) : [];
        this.userForm.propertyId = nv
        && nv.property ? nv.property.map((p) => p.newPropertyCode) : [];
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleSubmit() {
      this.$refs.userForm
        .validate()
        .then(() => {
          if (!this.isInternalUser) {
            this.userForm.partnerId = [this.$store.getters.userPartner.id];
          }
          this.$emit('submit', { ...this.userForm });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetFields() {
      this.$refs.userForm.resetFields();
    },
    handleRoleChange() {
      this.userForm.partnerId = [];
      this.userForm.propertyId = [];
    },
  },
};
</script>

<style scoped>
.reserve-fields {
  flex-direction: row-reverse;
}
@media screen and (max-width: 769px) {
  .reserve-fields {
    flex-direction: column-reverse;
  }
}
</style>
