<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-input
        v-model:value="name"
        :size="size"
        style="min-width: 300px"
        :placeholder="$t('placeholder')"
        @change="handleFilter"
      />
    </div>
    <div class="column is-narrow">
      <InputRole v-model:value-model="role" style="min-width: 200px" @change="handleFilter" />
    </div>
    <div class="column">
      <a-button
        v-show="name || role"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "placeholder": "email, first name, or last name",
    "Clear": "Clear",
  },
  "ja": {
    "placeholder": "メールアドレス, 名, 姓",
    "Clear": "クリア",
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { cleanObject } from '@/utils/util';
import InputRole from '@/views/user/components/InputRole';

export default {
  name: 'SearchForm',
  components: { InputRole },
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['filter'],
  data() {
    return {
      name: undefined,
      role: undefined,
    };
  },
  computed: {},
  mounted() {
    this.name = this.$route.query.name || undefined;
    this.role = this.$route.query.role || undefined;
  },
  methods: {
    // eslint-disable-next-line func-names
    handleFilter: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        name: this.name,
        role: this.role,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.name = undefined;
      this.role = undefined;
      this.handleFilter();
    },
  },
};
</script>

<style scoped></style>
