<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Edit User') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mini">
        <UserForm
          ref="userForm"
          :is-submitting="isSubmitting"
          :show-roles="!isSelf"
          :partner-list="partnerList"
          :user="user"
          @submit="handleSubmit"
        />
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Edit User": "Edit User",
    "cannot-edit": "You cannot edit this user"
  },
  "ja": {
    "Edit User": "エディットユーザー",
    "cannot-edit": "このユーザーを編集できません"
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex';
import UserForm from '@/views/user/components/UserForm';

export default {
  name: 'EditUser',
  components: { UserForm },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: null,
      isSubmitting: false,
      error: '',
      partnerList: [],
    };
  },
  computed: {
    ...mapGetters({ isAdmin: 'isAdmin' }),
    isSelf() {
      return this.uid === this.$store.getters.uid;
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'edit-user') this.fetchData();
    },
  },
  created() {
    const { permissions } = this.$store.getters;
    if (permissions.includes('view-partner')) {
      this.getPartnerList();
    }
    this.fetchData();
  },
  methods: {
    getPartnerList() {
      this.$store.dispatch('partners/list', { infinite: true }).then((res) => {
        this.partnerList = res.list;
      });
    },
    fetchData() {
      this.error = '';
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('user/get', { uid: this.uid })
        .then((data) => {
          const { roles } = data;
          this.user = data;
          this.user.role = roles[0] && roles[0].name;
          this.user.partnerId = this.user.partner.length && this.user.partner.map((p) => p.id);
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        })
        .catch(() => {
          this.error = this.$t('cannot-edit');
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    async handleSubmit(data) {
      this.isSubmitting = true;
      this.error = '';
      try {
        let needsReload = false;

        await this.$store.dispatch('user/update', { ...data, uid: this.uid });

        if (this.isSelf) {
          const { lang } = data;
          const isNewLang = this.user?.lang !== lang;
          await this.$store.dispatch('user/refreshToken');

          if (isNewLang) {
            needsReload = true;
            await this.$store.commit('user/SET_LANG', lang);
          } else {
            // we are not reloading so we just update the user state
            await this.$store.dispatch('user/getInfo');
          }
        }

        this.isSubmitting = false;
        this.user = undefined;

        this.$router.push({ name: 'users' }).then(() => {
          if (needsReload) {
            this.$nextTick(() => {
              window.location.reload();
            });
          }
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          this.error = err.response.data.error.message;
        }
        this.isSubmitting = false;
      }
    },
  },
};
</script>
